/**
 * Created by amine on 15/07/2016.
 */
(function () {
    'use strict';

    module.exports = wsMessage;

    wsMessage.prototype.AUTHETICATION = 0;
    wsMessage.prototype.CALL = 1;
    wsMessage.prototype.REP_CALL = 2;
    wsMessage.prototype.SUB = 3;
    wsMessage.prototype.UNSUB = 4;
    wsMessage.prototype.PUB = 5;
    wsMessage.prototype.NOTIFY = 6;
    wsMessage.prototype.LOGOUT = 7;

    const generateId = require('shared/utils/unique-id');

    function wsMessage(type, topic) {
        this.id = generateId();
        this.type = type;
        this.topic = topic;
        this.body = {};
        this.error = false;
        this.exclude = true;
        this.send_to = [];

        this.destroy = destroy;

        function destroy() {
            this.id = null
            this.type = null;
            this.topic = null;
            this.body = null;
            this.error = null;
            this.exclude = null;
            this.send_to = null;
        }
    };


})()