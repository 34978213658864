(function () {
    'use strict';

    class AuthRun {
        constructor(authService, $auth, $timeout, $window, $transitions, configService, mnSidenav, $templateCache) {
            this.$auth = $auth;
            this.$window = $window;
            this.$timeout = $timeout;
            this.mnSidenav = mnSidenav;
            this.authService = authService;
            this.configService = configService;

            $templateCache.put('/ng-busy/loader.html', require('shared/views/loader.html'));

            this.firstNavigation = $transitions['onBefore']({}, () => this.firstPage());

            $transitions['onBefore']({}, (transition) => this.requireAuthentication(transition));
            $transitions['onStart']({}, (transition) => this.sideNavLockHandle(transition));
            $transitions['onFinish']({}, (transition) => this.onFinishHandle(transition));

            this.successTransition = $transitions['onSuccess']({}, () => this.handleSuccess());
        }

        static create() {
            return new AuthRun(...arguments);
        }

        firstPage() {
            this.firstNavigation();

            if (this.$auth.isAuthenticated()) return this.authService.authenticated();
            else return true;
        }

        requireAuthentication(transition) {
            const $state = transition.router.stateService;
            const $targetState = transition.targetState();
            const $targetStateName = $targetState.state().name;

            this.authService.lastState = _.eq($targetStateName, 'auth') ? this.authService.lastState : $targetState;

            if (this.$auth.isAuthenticated()) {
                if ($targetStateName ==='auth') return this.authService.handleRoute('target');
                else return true;
            }
            
            else if ($targetStateName !== 'auth') return $state.target('auth');

            else return true
        }

        sideNavLockHandle(transition) {
            const state = transition.$to();
            const sideNavLock = _.get(state, 'data.sideNavLock');

            const $leftSideNav = $('#left');
            const $sideNavButton = $('.side-nav-button');

            if (sideNavLock && !$sideNavButton.is('.shown') && !$leftSideNav.is('.open-sidenav')) {
                this.mnSidenav.sideNavSubject.next(true);
            }

            else if (!_.get(state, 'parent.data.sideNavLock', false) && !sideNavLock) {
                this.mnSidenav.sideNavSubject.next(false);
            }

            return true;
        }

        onFinishHandle(transition) {
            const toState = transition.to();
            const params = transition.params('to');

            this.$timeout(() => {
                this.$window.document.title = `${_.get(toState, 'data.pageTitle')} | Pratisoft - Solution pour la santé`;
            });

            if (_.startsWith(toState['name'], "app.visit") || toState['name'] == "app.patient-dental") {
                this.configService.sideNaveSubject.next({
                    patient: params.pId,
                    visit: params.visitId,
                    subject: "activePatientAdded",
                });
            }

            else this.configService.sideNaveSubject.next({
                subject: "clearActivePatient"
            });
        }

        handleSuccess() {
            const $appLoader = $('.mn-app-loader', document.body);

            if ($appLoader.length > 0) {
                $appLoader.addClass("remove");

                this.$timeout(() => {
                    $appLoader.remove();
                    this.successTransition();
                });
            }
        }
    }

    AuthRun.create.$inject = ['authService', '$auth', '$timeout', '$window', '$transitions', 'configService', 'mnSidenav', '$templateCache'];

    module.exports = AuthRun.create;

})();