/**
 * Created by amine on 08/03/2018.
 */
(function () {

    const {Subject} = require("rxjs");

    class CareSheetService {
        constructor($q, $http, mnWebSocket, $state, $translate, $mdDialog) {
            this.ws = mnWebSocket;
            this.$q = $q;
            this.$state = $state;
            this.$http = $http;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;

            this.templateChanged = new Subject();

            this.invalidateCareSheet = this.careSheetValidation(false);
            this.validateCareSheet = this.careSheetValidation()
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$state", "$translate", "$mdDialog"];
        }

        getSheets(query) {
            return this.ws.call("care_sheet.CareSheet.list", query);
        }

        saveCareSheet(sheet) {
            let deferred = this.$q.defer();
            let url = '/api/care-sheet/';

            if (_.has(sheet, 'id')) url += `${sheet.id}/`;

            this.$http.post(url, sheet)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        getCareSheet(id) {
            let deferred = this.$q.defer();
            const url = `/api/care-sheet/${id}/`;

            this.$http.get(url)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        deleteCareSheet(sheet) {
            let deferred = this.$q.defer();
            const url = `/api/care-sheet/${sheet.id}/`;

            this.$http.delete(url)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        careSheetValidation(validate = true) {
            return (sheet) => {
                let deferred = this.$q.defer();
                const url = `/api/care-sheet/${sheet.id}/`;
                let data = {
                    is_validated: validate,
                    validate_at: validate ? moment() : null
                };

                this.$http.put(url, data)
                    .then((response) => {
                        deferred.resolve(response.data);
                    }, deferred.reject);

                return deferred.promise;
            }
        }

        mainState() {
            this.$state.go("app.care-sheet.main", {}, {inherit: true});
        }

        formState(query) {
            this.$state.go("app.care-sheet.form", query, {inherit: true});
        }

        visitCareSheet(obj) {
            this.formState({visitId: obj.id})
        }

        patientCareSheet(obj) {
            this.formState({patientId: obj.id})
        }

        careSheetValidationDialog(validate) {
            return this.$mdDialog.confirm()
                .parent($(document.body))
                .clickOutsideToClose(true)
                .title('')
                .textContent(this.$translate.instant(validate ? 'validation_warning' : 'invalidate_warning'))
                .ariaLabel('validation_warning')
                .ok(this.$translate.instant('confirm'))
                .cancel(this.$translate.instant('cancel'))
        }

        careSheetDeletingDialog() {
            return this.$mdDialog.confirm()
                .parent($(document.body))
                .clickOutsideToClose(true)
                .title('')
                .textContent(this.$translate.instant('delete_warning'))
                .ariaLabel('delete_warning')
                .ok(this.$translate.instant('confirm'))
                .cancel(this.$translate.instant('cancel'))
        }

        getCareSheetTemplates() {
            const deferred = this.$q.defer();

            this.$http.get('/api/care-sheet-template/')
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        getCareSheetTemplate(id) {
            const deferred = this.$q.defer();

            this.$http.get(`/api/care-sheet-template/${id}/`)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        saveCareSheetTemplate(template) {
            let deferred = this.$q.defer();
            let url = '/api/care-sheet-template/';

            if (_.has(template, 'id')) url += `${template.id}/`;

            this.$http.post(url, template)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        duplicateCareSheetTemplate(template) {
            let deferred = this.$q.defer();
            let url = `/api/care-sheet-template/${template}/duplicate-template/`;

            this.$http.get(url, template)
                .then((result) => {
                    deferred.resolve(result.data);
                }, (data) => {
                    deferred.reject(data);
                });

            return deferred.promise;
        }

        removeCareSheetTemplate(template) {
            let deferred = this.$q.defer();
            let url = `/api/care-sheet-template/${template}/`;

            this.$http.delete(url, template)
                .then((result) => {
                    deferred.resolve(result.data);
                }, (data) => {
                    deferred.reject(data);
                });

            return deferred.promise;
        }

        templateFormState(item, reload = false) {
            this.$state.go("app.parameters.care-sheet-printing.form", {
                id: _.get(item, "id", null),
            }).then(reload ? this.$state.reload : _.noop);
        }
    }

    module.exports = CareSheetService;
})();
