(function () {

    'use strict';

    const ZONES = require('../json/tooth-zones.json');
    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');

    class DentalProcedureDialogCtrl {
        constructor($scope, dragulaService, $mdDialog, dentalService, $translate, mnWebSocket) {
            this.$scope = $scope;
            this.dialog = $mdDialog;
            this.mnWebSocket = mnWebSocket;
            this.dentalService = dentalService;

            dragulaService.options($scope, "field-bag", {
                revertOnSpill: false,
                moves: (el, container, handle) => {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            this.transparentAble = _.chain(ZONES)
                .filter(['isTransparentAble', true])
                .orderBy(i => $translate.instant(i.label))
                .value()

            this.unTransparentAble = _.chain(ZONES)
                .filter(['isTransparentAble', false])
                .orderBy(i => $translate.instant(i.label))
                .value()

            this.chartID = _.uniqueId('svg-parent-');
            this.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('color')});
            this.procedure = {
                style: "", steps: [], overlay: [], charting: []
            }
        }

        static get $inject() {
            return ["$scope", "dragulaService", "$mdDialog", "dentalService", "$translate", "mnWebSocket"];
        }

        $onInit() {
            this.$scope.$watch('vm.procedure.charting', (value) => this.handleChartingChange(value), true);

            this.promise = this.dentalService.getDentalProcedureDetail(this.item)
                .then(data => this.procedure = data);
        }

        cancel() {
            this.dialog.cancel();
        }


        addZones(transparent) {
            this.procedure.charting.push({
                zones: [],
                is_transparent: transparent
            })
        }

        addSurface() {
            this.procedure.charting.push({
                zones: [],
                is_surface: true
            })
        }

        hasSurface() {
            return this.procedure['is_multi_teeth'] || this.procedure['is_all_mouth'] || !_.chain(this.procedure.charting).find('is_surface').isEmpty().value();
        }

        handleChartingChange(value) {
            if (_.isEmpty(value)) this.procedure = _.assign(this.procedure, {style: "", overlay: []});
            else this.mnWebSocket.call('dental_consultation.ToothProcedure.generate_stylesheet', {element: this.chartID, value})
                .then(data => this.procedure = _.assign(this.procedure, data));
        }

        removeCharting(index) {
            this.procedure['charting'].splice(index, 1);
        }

        removeSurface() {
            if (this.procedure['is_multi_teeth'] || this.procedure['is_all_mouth']) {
                this.procedure['charting'] = _.filter(this.procedure['charting'], i => !i.is_surface);
            }
        }

        addStep() {
            this.procedure['steps'].push({});
        }

        removeStep(index) {
            this.procedure['steps'].splice(index, 1);
        }

        submit() {
            this.procedure = _.assign(this.procedure, {ignore: true});
            this.promise = this.dentalService.handleDentalProcedureDetail(this.procedure)
                .then((data) => {
                    this.promise = this.dentalService.getDentalProcedureTableItem(data.id)
                        .then(this.dialog.hide);
                });
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DentalProcedureDialogCtrl,
        template: require("dental-consultation/views/dental-procedure-dialog.tpl.html"),
    };

})();