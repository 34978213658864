/**
 * Created by Hp on 03/05/2017.
 */
(function () {
    'use strict';
    module.exports = BillingCtrl;

    BillingCtrl.$inject = ["$state", "billingService", "$mdToast", "$translate", "printService", "$stateParams", "$mdDialog", "frontDeskService", "system", "configService", "$transitions"];

    function BillingCtrl($state, billingService, $mdToast, $translate, printService, $stateParams, $mdDialog, frontDeskService, system, configService, $transitions) {
        var vm = this;
        var doc_type = $stateParams['type'];
        var resource = $stateParams['resource'];
        vm.$onInit = init;
        vm.toggle = false;
        vm.filterColumns = [];
        vm.filterQuery = {};
        vm.handleReload = false;
        vm.addClick = addClick;

        function init() {
            vm.filter = {is_deleted: {$ne: true}};
            if (_.eq($state.$current['name'], "app.billing")) toState();
            let listener = $transitions.onBefore({}, toList);

            function toList(transition) {
                let state = transition.to();
                if (_.eq(state.name, "app.billing")) {
                    toState();
                    return false;
                }
                return true;
            }

            function toState() {
                vm.promise = configService.get('billing_config')
                    .then(data => {
                        vm.defaultTab = _.get(data, 'defaultTab') == "contract_list" ? "app.billing.contract" : "app.billing.invoice"
                        $state.go(vm.defaultTab, {})
                    });
            }

            var multipleAction = []
            let singleAction = [
                {
                    icon: 'mdi-pencil',
                    label: 'edition',
                    resource: resource,
                    action: 'create',
                    behavior: 'disable',
                    method: goToEdition
                },
                {
                    icon: 'mdi-printer',
                    label: 'print',
                    resource: resource,
                    action: 'create',
                    behavior: 'disable',
                    method: print
                }]
            if (doc_type == 'isInvoice') {
                singleAction.push({
                    icon: 'mdi-currency-usd',
                    label: 'paye',
                    resource: resource,
                    action: 'create',
                    behavior: 'disable',
                    condition: 'item.is_valid && !item.is_paid',
                    method: payeInvoice
                })
            }
            if (doc_type == 'isContract') {
                singleAction.push(
                    {
                        icon: 'mdi-account-card-details',
                        label: 'resume',
                        resource: 'patient',
                        action: 'get',
                        behavior: 'disable',
                        method: patientResume
                    }, {
                        icon: 'mdi-history',
                        label: 'historic',
                        resource: resource,
                        action: 'create',
                        behavior: 'disable',
                        condition: 'item.is_valid',
                        method: contractHistoric
                    }, {
                        icon: 'mdi-receipt',
                        label: 'billing_visit',
                        resource: resource,
                        action: 'create',
                        behavior: 'disable',
                        condition: 'item.is_valid',
                        method: billContract
                    }, {
                        icon: 'mdi-currency-usd',
                        label: 'new_encasement',
                        resource: resource,
                        action: 'create',
                        behavior: 'disable',
                        condition: 'item.is_valid ',//&& !item[\'billing_details\'][\'canceled\']
                        method: billingService.payContract
                    }
                );
                multipleAction.push(
                    // {
                    //     icon: 'mdi-currency-usd',
                    //     label: 'paye',
                    //     resource: resource,
                    //     action: 'create',
                    //     behavior: 'disable',
                    //     condition: 'item.is_valid && !item.is_paid && !item[\'billing_details\'][\'canceled\']',
                    //     method: payContract
                    // },
                    {
                        icon: 'mdi-receipt',
                        label: 'billing_visit',
                        resource: resource,
                        action: 'create',
                        behavior: 'disable',
                        condition: 'item.is_valid',
                        method: billContract
                    },
                    {
                        icon: 'mdi-file-excel-box',
                        label: 'export_custom_xls',
                        resource: resource,
                        action: 'get',
                        behavior: 'disable',
                        method: exportCustomXls,
                        isStatic: true
                    },
                )
            }
            vm.actions = {
                single: singleAction,
                multiple: multipleAction
            }
        }

        function goToEdition(obj) {
            switch (doc_type) {
                case 'isQuotation':
                    $state.go('app.billing.quotation-form', {quotationId: obj.id});

                    break;
                case 'isInvoice':
                    $state.go('app.billing.invoice-form', {invoiceId: obj.id})
                    break;
                case 'isPayment':
                    $state.go('app.billing.payment-form', {paymentId: obj.id});
                    break;
                case 'isExpense':
                    $state.go('app.billing.expense-form', {expenseId: obj.id});
                    break;
                case 'isContract':
                    $state.go('app.billing.contract-form', {contractId: obj.id});
                    break;
            }
        }

        function deleteInvoice(objs) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                _.forEach(objs, function (obj) {
                    var deleteFunc = null
                    switch (doc_type) {
                        case 'isQuotation':
                            deleteFunc = billingService.deleteQuotation;
                            break;
                        case 'isInvoice':
                            deleteFunc = billingService.deleteInvoice
                            break;
                        case 'isPayment':
                            deleteFunc = billingService.deletePayement;
                            break;
                    }
                    obj['is_valid'] == false && _.isFunction(deleteFunc) ? deleteFunc(obj.id).then(_.isFunction(vm.reset) ? vm.reset : _.noop) : showWarning('impossible_delete');

                })
            });
        }

        function payeInvoice(obj) {
            var objs = Array.isArray(obj) ? obj : [obj];
            var lines = [];
            var beneficiary = null;
            var beneficiary_type = null;
            if (_.every(objs, function (item) {
                return item.beneficiary_type == 'PATIENT' && item.beneficiary_name == objs[0].beneficiary_name;
            })) {
                beneficiary = objs[0].beneficiary;
                beneficiary_type = 'P';
            }
            if (_.every(objs, function (item) {
                return item.beneficiary_type == 'ORGANIZATION' && item.beneficiary_name == objs[0].beneficiary_name;
            })) {
                beneficiary = objs[0].beneficiary;
                beneficiary_type = 'T';
            }

            _.forEach(objs, function (obj) {
                if (!obj['is_paid']) lines.push({
                    total_amount: obj.taxed_amount,
                    paid_doc: _.assign(obj, {_module: 'billing.models', _model: 'Invoice'}),
                    encasement_amount: 0.0,
                    remaining_amount: obj.remaining_amount
                })

            });

            if (lines.length > 0) $state.go('app.billing.payment-form', {
                paymentId: null,
                paymentLines: lines,
                beneficiaryType: beneficiary_type,
                beneficiary: beneficiary
            });
        }

        function showWarning(txt) {
            var simpleToast = $mdToast.simple()
                .textContent($translate.instant(txt))
                .position("top right")
                .hideDelay(3000);
            $mdToast.show(simpleToast);
        }

        function print(obj) {
            switch (doc_type) {
                case 'isQuotation':
                    printService.printItem('quotation', obj.id, null, null);

                    break;
                case 'isInvoice':
                    printService.printItem('invoice', obj.id, null, null);
                    break;
                case 'isPayment':
                    printService.printItem('payment', obj.id, null, null);
                    break;
                case 'isContract':
                    printService.printItem('contract', obj.id, null, null);
                    break;
            }
        }

        function addClick(type) {
            $state.go("app.billing.contract-form", {type: type, contractId: null});
        }


        function billContract(obj) {
            let objs = Array.isArray(obj) ? obj : [obj];
            if (!Array.isArray(obj)) {
                $state.go("app.billing.invoice-form", {
                    invoiceId: null,
                    visits: [obj],
                    beneficiary: obj.beneficiary,
                    beneficiaryType: 'PATIENT',
                    isContract: true,
                    invoiceDate: null
                });
            }
            else {
                vm.promise = billingService.generateInvoices(_.chain(obj).filter({is_valid: true}).map('id').value()).then(vm.reset)
            }
        }

        function patientResume(obj, ev) {
            frontDeskService.entryResume({
                'patient-id': _.get(obj, 'beneficiary.id'),
                isContract: true,
                onlyResume: true
            }, ev);

        }

        function exportCustomXls(data, query) {
            let columns = [
                {"label": "mle"},
                {"label": "last_name"},
                {"label": "first_name"},
                {"label": "taxed_amount"},
                {'label': 'ad_alone'},
                {'label': 'couple'},
                {'label': 'ch_0'},
                {'label': 'ch_1'},
                {'label': 'ch_2'},
                {'label': 'insurances'},
                {'label': 'remaining_amount'},
                {'label': 'phone_number'},
                {'label': 'email'},
                {'label': 'default_insurance'},
                {'label': 'tariff'}

            ];

            let translatedColumns = _.reduce(columns, reduceFunc, {
                file_name: $translate['instant']("SUBSCRIPTION"),
                page_name: $translate['instant']('table_export_excel_page_name')
            });

            let q = _.assign({
                translated_columns: translatedColumns,
            }, _.pick(query, ['search', 'search_all', 'filter']));

            vm.promise = billingService.generateCustomXls('billing.Contract', q, null)
                .then(success);

            function reduceFunc(items, item) {
                return _.set(items, item.label, $translate['instant'](item.label));
            }

            function success(data) {
                data.download();
            }
        }
        
        function contractHistoric(row) {
            billingService.contractHistoric(row).then(done, _.noop);
            function done(data) {
                if(data){
                     vm.reset();
                }
            }
        }
    }
})()