(function () {

    'use strict';

    class ToothHistoricDialogCtrl {
        constructor($mdDialog, system, dentalService, moment, dentalUtilsService) {
            this.moment = moment;
            this.dialog = $mdDialog;
            this.dentalService = dentalService;
            this.dentalUtilsService = dentalUtilsService;

            this.dateFormat = system['date_format'].js;
            this.doneProcedures = this.doneProcedures ? this.doneProcedures : [];
        }

        static get $inject() {
            return ["$mdDialog", "system", "dentalService", "moment", "dentalUtilsService"];
        }

        $onInit() {
            this.doneItem = _.chain(this.doneProcedures).groupBy('date').toPairs().orderBy(item => {
                return this.moment(item[0], this.dateFormat).valueOf();
            }, 'desc').value();

            this.promise = this.dentalService.toothPlanedProcedures(this.tooth, this.patient)
                .then(data => this.planedItem = data);
        }

        cancel() {
            this.dialog.cancel();
        }

        showPlanSteps(procedure, ev) {
            this.dentalUtilsService.planProcedureSteps(procedure, ev, true);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ToothHistoricDialogCtrl,
        template: require("dental-consultation/views/tooth-historic-dialog.tpl.html"),
    }

})()