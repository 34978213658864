(function () {
    'use strict';

    const LANG = (ctx => {
        let keys = ctx.keys();
        let values = keys.map(ctx);
        return keys.reduce((o, k, i) => _.assign(o, values[i]), {});
    })(require.context('../../', true, /^.*lang.json$/));

    class TranslationsConfig {
        constructor(translateProvider) {
            translateProvider.translations("i18n", LANG);
            translateProvider.preferredLanguage("i18n");
            translateProvider.useSanitizeValueStrategy('escaped');
        }

        static create() {
            return new TranslationsConfig(...arguments);
        }
    }

    TranslationsConfig.create.$inject = ['$translateProvider'];

    module.exports = TranslationsConfig.create;

})();
