(function () {

    'use strict';

    const WEB_CAM_DIALOG = require('shared/dialogs/web-cam-dialog');

    class PatientPanoramicCtrl {
        constructor(dentalService, dentalUtilsService, $mdDialog, $translate) {
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.dentalService = dentalService;
            this.dentalUtilsService = dentalUtilsService;

            this.patient = this.patient ? this.patient : null;
            this.delaySave = _.mnDelay(() => this.save(), 750);
        }

        static get $inject() {
            return ['dentalService', 'dentalUtilsService', '$mdDialog', '$translate'];
        }

        $onInit() {
        }

        $onChanges(obj) {
            let patient = _.get(obj, 'patient.currentValue');

            if (patient) this.promise = this.dentalService.patientPanoramics(patient)
                .then(data => this.success(data));
        }

        openItem(index, ev) {
            const previews = _.orderBy(this.patientPanoramics.previews, 'sort_date', 'desc');
            this.dentalUtilsService.visualisePanoramics(previews, index, ev)
                .then(files => this.uploadPanoramic(files));
        }

        removeItem(index, ev) {
            const datePath = `panoramics.${index}.uploaded_at`;
            const date = _.get(this.patientPanoramics, datePath);

            const confirm = this.dialog.confirm()
                .multiple(true)
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok(this.$translate['instant']('confirm_ok'))
                .cancel(this.$translate['instant']('confirm_cancel'))
                .title(this.$translate['instant']('dental_module_patient_panoramics_remove_confirm', {date}));

            return this.dialog.show(confirm).then(
                () => this.remove(index)
            );
        }

        remove(index) {
            this.patientPanoramics['panoramics'].splice(index, 1);
            this.save();
        }

        showCapture(ev) {
            return this.dialog.show(_.assign({}, WEB_CAM_DIALOG, {
                targetEvent: ev,
                locals: {
                    multi: true
                }
            })).then(files => this.uploadPanoramic(files));
        }

        uploadPanoramic(files) {
            if (_.isEmpty(files)) return;
            else this.promise = this.dentalService.uploadPanoramic(this.patientPanoramics.id, files)
                .then(data => this.success(data));
        }

        save() {
            this.promise = this.dentalService.updatePanoramics(this.patientPanoramics)
                .then(data => this.success(data));
        }

        success(data) {
            this.patientPanoramics = data;
            this.patientPanoramics['panoramics'] = _.orderBy(data['panoramics'], 'sort_date', 'desc');
        }
    }

    module.exports = {
        bindings: {
            patient: '<patient',
        },
        controllerAs: "vm",
        controller: PatientPanoramicCtrl,
        template: require('dental-consultation/views/patient-panoramics.tpl.html')
    };

})();