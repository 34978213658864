/**
 * Created by amine on 22/10/2019.
 */

(function () {

    'use strict';

    class DentalBaseQuotationSearchDialogCtrl {
        constructor($mdDialog, dentalService, $translate, system) {
            this.dialog = $mdDialog;
            this.dentalService = dentalService;
            this.dateFormat = system['date_format'].js;

            this.selected = [];
            this.query = {
                limit: 10,
                page: 1,
                order: "-creation_date",
                hide_paid: false,
                hide_no_amount: true,
                patientFilter: {}
            };

            this.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            };

            this.options = [20, 15, 10, 5];
            this.selectedItem = null;
            this.items = [];
            this.patient = this.patient || null;
            this.type = this.type || 'quotation';
        }

        static get $inject() {
            return ["$mdDialog", "dentalService", "$translate", "system"];
        }

        $onInit() {
            this.promise = this.dentalService[this.type === 'quotation' ? 'getPatientQuotations' : 'getPatientPlans'](_.get(this.patient, "id"))
                .then(data => {
                    this.selectedItem = null;
                    this.itemTotal = data.length;
                    this.items = data;
                    if (this.itemTotal < ((this.query.page - 1) * this.query.limit)) this.query.page = 1;
                });
        }

        getItems() {
            const order = this.query.order[0] === '-' ? 'desc' : 'asc';
            const min = (this.query.page - 1) * this.query.limit, max = (this.query.page) * this.query.limit;

            return _.chain(this.items)
                .orderBy(item => moment(item.creation_date, this.dateFormat).valueOf(), order)
                .slice(min, max)
                .value();
        }

        onItemPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
        }

        addSelection() {
            this.dialog.hide(this.selected);
        }

        showProcedures(item, $event) {
            $event.stopPropagation();

            if (this.selectedItem === item.id) this.selectedItem = -1;
            else this.selectedItem = item.id;
        }

        onItemReorder(order) {
            this.query = _.assign(this.query, {order: order});
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: DentalBaseQuotationSearchDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/dental-base-quotation-search-modal.tpl.html"),
    };


})();