/**
 * Created by Hp on 04/05/2017.
 */
(function () {

    'use strict';

    const ProcedureSearchDialog = {
        controller: ProcedureSearchDialogCtrl,
        controllerAs: "vm",
        template: require("billing/views/procedure-search-modal.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true
    };

    ProcedureSearchDialogCtrl.$inject = ["$mdDialog", "visitService", "$translate"];

    function ProcedureSearchDialogCtrl($mdDialog, visitService, $translate) {
        let vm = this;
        vm.cancel = $mdDialog.cancel;
        vm.selected = [];
        vm.addSelection = addSelection;
        vm.onPaginate = onPaginate;
        vm.onReorder = onReorder;
        vm.procedureQuery = {
            limit: 10,
            page: 1,
            order: "-date",
            hide_paid: false,
            hide_no_amount: true
        };
        vm.paginationLabel = {
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
            of: $translate['instant']('of')
        };
        vm.options = [10, 20, 100];
        vm.procedures = [];
        init();

        function init() {
            visitService.getProcedures().then(success);

            function success(data) {
                vm.procedureTotal = data.length;
                vm.procedures = data;
                getData();
            }
        }

        function addSelection() {
            $mdDialog.hide(vm.selected);
        }

        function onPaginate(page, limit) {
            vm.procedureQuery = _.assign(vm.procedureQuery, {page: page, limit: limit});
            getData();
        }

        function getData() {
            if (vm.procedures.length < ((vm.procedureQuery.page - 1) * vm.procedureQuery.limit)) {
                vm.procedureQuery.page = 1;
            }
        }

        function onReorder(order) {
            vm.procedureQuery = _.assign(vm.procedureQuery, {order: order});
            getData();
        }

    }

    module.exports = ProcedureSearchDialog;

})();