/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    module.exports = ConsultationBodyCtrl;

    const {BehaviorSubject} = require("rxjs");

    ConsultationBodyCtrl.$inject = ["consultationService", "$transition$", "$state", "$q", "$scope", "system"];

    function ConsultationBodyCtrl(consultationService, $transition$, $state, $q, $scope, system) {
        let vm = this;

        const params = $transition$.params('to');
        const dateFormat = system['date_format'].js;

        let currentCopy = _.get(params, 'copy');
        let template = _.get(params, 'template');
        let currentVisit = _.get(params, 'visitId');
        let currentConsultation = _.get(params, 'consultation');

        vm.$onInit = init;
        vm.lock = lock;

        vm.preSave = startAutoSave;
        vm.handleAutoSave = _.mnDelay(startAutoSave, 750);

        function init() {
            /**
             * added for the multiple components of the consultation body to detect
             */
            vm.titles = [];
            vm.consultationSaved = new BehaviorSubject(null);

            vm.isLast = _.get(params, 'is-last');
            vm.readOnly = _.get(params, 'read-only');
            vm.currentPatient = _.get(params, 'pId');

            let promises = [consultationService.getConfigItem(template)];

            if (!_.isNull(currentConsultation)) promises.push(consultationService.getConsultation(currentConsultation));
            else if (!_.isNull(currentCopy)) promises.push(consultationService.getConsultation(currentCopy));
            else vm.consultation = promises.push(
                {
                    visit_id: currentVisit,
                    consultation_date: moment().format(dateFormat),
                    pathologies: []
                }
            );

            vm.promise = $q.all(promises).then(success);

            function success(data) {
                vm.consultationConfig = data[0];
                vm.consultation = _.isNull(currentCopy) ? data[1] : _.assign(
                    {
                        visit_id: currentVisit,
                        consultation_date: moment().format(dateFormat),
                    },
                    _.pick(data[1], 'blocks')
                );

                vm.consultationSaved.next(_.isNull(currentConsultation) ? null : currentConsultation)

                consultationService.currentSubscription.next({
                    currentActiveConsultId: !_.isNull(currentConsultation) && vm.readOnly ? currentConsultation : -1,
                    currentEditConsult: !_.isNull(currentConsultation) && !vm.readOnly ? [currentConsultation, false] : -1,
                    currentConsultationDate: _.get(vm.consultation, "consultation_date")
                });

                if (!_.isNull(currentCopy)) vm.handleAutoSave();
            }

            const lockSubscription = consultationService.lockSubject.subscribe(handleLock);

            function handleLock(item) {
                vm.isLocked = item.lock;
                $scope.$applyAsync();
            }

            vm.$onDestroy = onDestroy;

            function onDestroy() {
                lockSubscription.unsubscribe();
            }
        }

        function startAutoSave() {
            return consultationService.autoSave(vm.consultation, currentVisit, vm.currentPatient, vm.consultationConfig)
                .then(success);

            function success(data) {
                if (!vm.consultation.id) {
                    vm.consultation.id = data.id;

                    consultationService.newItemSubject.next(data.id);

                    $state.go("app.visit.consultation.body", {
                        copy: null,
                        consultation: data.id,
                    }, {location: 'replace'});
                }

                vm.consultationSaved.next(data.id);
            }
        }

        function lock() {
            consultationService.lockSubject.next({lock: !vm.isLocked});
        }
    }

})();
