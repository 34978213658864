/**
 * Created by Hp on 04/05/2017.
 */
(function () {

    'use strict';

    var ContractRenewDialog = {
        controller: ContractRenewDialogCtrl,
        controllerAs: "vm",
        template: require("billing/views/contract-renew.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple:true,
        focusOnOpen: false

    };

    ContractRenewDialogCtrl.$inject = ["$mdDialog", "$translate", "system"];

    function ContractRenewDialogCtrl($mdDialog, $translate, system) {
        var vm = this;
        let dateFormat = system['date_format'].js;

        vm.cancel = $mdDialog.cancel;
        vm.save=save;
        init();

        function init() {
            vm.start_at=moment().format(dateFormat);
            vm.minDate = moment(vm.PrevEndDate, dateFormat).toDate();
        }

        function save() {
            $mdDialog.hide(vm.start_at);
        }


    }

    module.exports = ContractRenewDialog;

})();