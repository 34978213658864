(function () {

    'use strict';

    class FurcationButtonDirective {
        constructor() {
            this.priority = 0;
            this.restrict = "A"
            this.require = "ngModel";
            this.scope = {
                ngModel: "=", change: "&ngChange"
            }
        }

        static create() {
            return new FurcationButtonDirective(...arguments);
        }

        link(scope, element, attrs, ctrl) {
            element.on("click", () => this.increment(scope, ctrl));

            scope.$watch("ngModel", (value) => {
                element.removeClass((index, className) => (className.match(/\bfurcation-level\S+/g) || []).join(' '));
                element.addClass(`furcation-level-${value}`);
            });
        }

        increment(scope, ctrl) {
            let modelValue = _.isNil(ctrl.$modelValue) ? 1 : ctrl.$modelValue == 3 ? 0 : ctrl.$modelValue + 1;

            ctrl.$setViewValue(modelValue);
            ctrl.$commitViewValue();
            ctrl.$validate();

            scope.change();
        }
    }

    FurcationButtonDirective.create.$inject = [];

    module.exports = FurcationButtonDirective.create;

})();