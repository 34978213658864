/**
 * Created by BETALOS on 20/01/2017.
 */
(function () {
    'use strict';

    const cgBusyDefaults = {
        backdrop: true,
        minDuration: 300,
        wrapperClass: 'cg-busy',
        templateUrl: '/ng-busy/loader.html',
    }

    module.exports = cgBusyDefaults;

})();