/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    const angular = require('angular');

    const cgBusyDefaults = require('./configs/app.loader');

    const appAuth = require('./configs/app.auth');
    const dialogConfig = require('./configs/app.dialog');
    const appWs = require('./configs/app.ws');
    const dateConfig = require('./configs/app.date');
    const translations = require('./configs/app.i18n');
    const materialConfig = require('./configs/app.material');
    const qConfig = require('./configs/app.Q');
    const routeConfig = require('./configs/app.routes');
    const sanitizeConfig = require('./configs/app.sanitize');
    const httpConfig = require('./configs/app.http');
    const toastConfig = require('./configs/app.toast');

    const sidenav = require('./components/sidenav');
    const mnPrint = require('./components/mn-print');
    const numberContainer = require('./components/number-container');

    const time = require('./directives/time');
    const date = require('./directives/date');
    const input = require('./directives/input');
    const number = require('./directives/number');
    const scroll = require('./directives/scroll');
    const validate = require('./directives/validate');
    const bindHtml = require('./directives/bind-html');
    const printLink = require('./directives/print');
    const scrollSref = require('./directives/scroll-sref');
    const lineCounter = require('./directives/line-counter');
    const srcBlobLoader = require('./directives/src-blob-loader');

    const asyncClick = require('./directives/async-click');
    const asyncSubmit = require('./directives/async-submit');

    const mnNumberFilter = require('./filters/number-filter');

    // stile in es5
    const printService = require('./services/printService');

    //moved to es6
    const ModalService = require('./services/modalService');
    const ConfigService = require('./services/configService');
    const ScrollService = require('./services/scrollService');
    const ThemeProvider = require('./services/themeService');
    const SidenavService = require('./services/sidenavService');
    const StorageService = require('./services/storageService');

    const mnWebSocket = require('./services/webSocket');

    const appRun = require('./run/app.run');

    angular
        .module("medinet")

        .constant('moment', window.moment)

        .value('cgBusyDefaults', cgBusyDefaults)

        .config(appWs)
        .config(appAuth)
        .config(qConfig)
        .config(httpConfig)
        .config(dateConfig)
        .config(toastConfig)
        .config(routeConfig)
        .config(translations)
        .config(dialogConfig)
        .config(materialConfig)
        .config(sanitizeConfig)

        // moved to es6
        .run(appRun)

        // still in es5
        .service('printService', printService)
        .provider('mnWebSocket', mnWebSocket)

        // moved to es6
        .provider('mnTheme', ThemeProvider)
        .service('mnSidenav', SidenavService)
        .service('modalService', ModalService)
        .service('configService', ConfigService)
        .service('scrollService', ScrollService)
        .service('storageService', StorageService)


        // moved to es6
        .directive("input", input)
        .directive("mnTime", time)
        .directive("mnDate", date)
        .directive("mnScroll", scroll)
        .directive("mnNumber", number)
        .directive("mnBindHtml", bindHtml)
        .directive("mnValidate", validate)
        .directive("mnPrintLink", printLink)
        .directive("mnScrollSref", scrollSref)
        .directive("asyncClick", asyncClick)
        .directive("asyncSubmit", asyncSubmit)
        .directive("lineCounter", lineCounter)
        .directive("srcBlob", srcBlobLoader)

        // moved to es6
        .filter("mnNumber", mnNumberFilter)

        // moved to es6
        .component("mnSidenav", sidenav)
        .component("mnNumberContainer", numberContainer)

        // still in es5
        .component("mnPrint", mnPrint)
})();