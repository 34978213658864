(function () {

    'use strict';

    class PlanStockDialogCtrl {
        constructor($mdDialog, dentalService) {
            this.dialog = $mdDialog;
            this.dentalService = dentalService;

            this.readOnly = false;
            this.plan = this.plan ? this.plan : {};

            this.planForm = {id: this.plan.id};
            this.hasDeliveries = _.get(this.plan, 'has_deliveries', false);
        }

        static get $inject() {
            return ["$mdDialog", "dentalService"];
        }

        $onInit() {
            if (this.hasDeliveries) this.promise = this.dentalService.getPlanDelivery(this.plan)
                .then(data => this.planForm = data)

            else this.planForm.delivery_document = {
                lines: [],
                price_mode: 'TTC',
                doc_date: this.plan.creation_date,
                patient: {'id': this.plan.patient_id},
            }
        }

        submit() {
            this.promise = this.dentalService.handlePlanDelivery(this.planForm)
                .then(() => this.dialog.hide(true));
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PlanStockDialogCtrl,
        template: require("../views/dental-plan-stock-dialog.tpl.html"),
    };

})();