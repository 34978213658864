/**
 * Created by BETALOS on 26/02/2016.
 */
(function () {

    'use strict';

    class DateConfig {
        constructor($mdDateLocaleProvider, system, moment) {
            this.dateFormat = system['date_format'].js;

            const oldDateFunction = moment.fn.date;
            const oldStartOfFunction = moment.fn.startOf;

            moment.fn.date = function () {
                if (arguments.length >= 1) {
                    let oldOffset = this.utcOffset();
                    let result = oldDateFunction.apply(this, arguments);
                    let newOffset = this.utcOffset();
                    this.add(newOffset - oldOffset, 'minutes');//restore proper time
                    return result;
                } else {
                    return oldDateFunction.apply(this, arguments);
                }
            };

            moment.fn.startOf = function (units) {
                if (units === 'day' || units === 'days' || units === 'd') {
                    let oldOffset = this.utcOffset();
                    let result = oldStartOfFunction.apply(this, arguments);
                    let newOffset = this.utcOffset();
                    this.add(newOffset - oldOffset, 'minutes');//restore proper time
                    return result;
                } else {
                    return oldStartOfFunction.apply(this, arguments);
                }
            }

            moment.fn.dates = moment.fn.date;
            moment.locale(system.lang);

            $mdDateLocaleProvider.months = moment.localeData().months();
            $mdDateLocaleProvider.shortMonths = moment.localeData().monthsShort();
            $mdDateLocaleProvider.days = moment.localeData().weekdays();
            $mdDateLocaleProvider.shortDays = moment.localeData().weekdaysShort();
            $mdDateLocaleProvider.firstDayOfWeek = moment.localeData()['_week']['dow'];

            $mdDateLocaleProvider.formatDate = (date) => {
                if (date) return moment(date).format(this.dateFormat);
                return null;
            };
        }

        static create() {
            return new DateConfig(...arguments);
        }
    }

    DateConfig.create.$inject = ["$mdDateLocaleProvider", "system", "moment"];

    module.exports = DateConfig.create;

})();