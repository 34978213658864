/**
 * Created by amine on 20/02/2017.
 */
(function () {
    'use strict';

    class NumberFilter {
        constructor(system) {
            this.currencyFloat = system['currency_float'];
            this.currencySymbol = system['currency_config']['currency_symbol'];
            this.filterCall = (input, type, currencyLabel) => this.filter(input, type, currencyLabel);
        }

        static create() {
            return new NumberFilter(...arguments).filterCall;
        }

        filter(input, type, currencyLabel) {
            let toFix = 0;
            let float = false;
            let currency = false;
            let percentage = false;
            let handledType = _.isUndefined(type) ? "integer" : type;

            if (!_.isNumber(input)) input = 0;

            switch (handledType) {
                case "percentage":
                    float = percentage = true;
                    break;
                case "currency":
                    float = currency = true;
                    break;
                case "float":
                    float = true;
                    break;
            }

            if (percentage) input = input * 100;
            if (float) toFix = currency ? this.currencyFloat : "2";

            // temp work around until i added the parameter for currency label
            return `${currency ? this.formatCurrency(input) : input.toFixed(toFix)}${currencyLabel ? ` ${this.currencySymbol}` : ''}`;
        }

        // now using fr specs
        formatCurrency(amount) {
            if (Number(amount) != amount) {
                return;
            }

            let formattedAmount = Math.abs(amount);
            // Fraction size

            formattedAmount = formattedAmount.toFixed(this.currencyFloat);

            // Format numeral by locale ID

            formattedAmount = formattedAmount.split('.').join(',');
            formattedAmount = formattedAmount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + ' ');

            // Format currency
            return `${Math.sign(amount) == -1 ? "-" : ""}${formattedAmount}`;
        }
    }

    NumberFilter.create.$inject = ['system'];

    module.exports = NumberFilter.create;


})();