(function () {

    'use strict';

    const generateId = require('shared/utils/unique-id');
    const Worker = require("worker-loader?name=ws.[hash].worker.js!../workers/storageWorker.js");

    class StorageService {
        constructor($q) {
            this.$q = $q;
            this.promises = {};
            this.worker = new Worker();
            this.worker.onmessage = ev => this.handleWorkerMessages(ev);
        }

        static get $inject() {
            return ['$q'];
        }

        setKey(key, value) {
            this.worker.postMessage({cmd: 'set', key, value});
        }

        getKey(key) {
            let id = generateId();
            this.promises[id] = this.$q.defer();

            this.worker.postMessage({id, cmd: 'get', key});

            return this.promises[id].promise;
        }

        removeKey(key) {
            this.worker.postMessage({cmd: 'remove', key});
        }

        handleWorkerMessages(ev) {
            let promise = _.get(this.promises, ev.data.id);

            if (!promise) return;
            else {
                _.unset(this.promises, ev.data.id)
                promise.resolve(ev.data.value);
            }
        }

    }

    module.exports = StorageService;

})();