(function () {

    'use strict';

    class PlanProcedureStepsDialogCtrl {
        constructor($mdDialog, $translate, system, moment, configService) {
            this.moment = moment;
            this.dialog = $mdDialog;
            this.$translate = $translate
            this.configService = configService;

            this.currentStep = null;
            this.dateTimeFormat = system['datetime_format'].js;
            this.procedure = this.procedure ? this.procedure : {};
            this.label = this.$translate.instant('dental_plan_procedure_steps_comment');
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system", "moment", "configService"];
        }

        $onInit() {
            this.steps = this.handleSteps();
            this.currentStep = this.getCurrentStep();

            this.promise = this.configService.get("dental_plan_step_dict", true)
                .then(config => {
                    this.field = {
                        label: this.label,
                        meta_data: {
                            dict_uid: config
                        }
                    };
                })
        }

        cancel() {
            this.dialog.cancel();
        }

        save() {
            let steps = _.map(this.steps, i => {
                if (i.fromStep) return _.omit(i, ['value', 'fromStep']);
                else return i;
            });

            this.dialog.hide(steps);
        }

        selectStep(step) {
            this.currentStep = step;
        }

        getCurrentStep() {
            return _.chain(this.steps).find(i => !i.is_done).thru(i => i ? i : _.last(this.steps)).value();
        }

        addStep(ev) {
            let confirm = this.dialog.mnPromptDialog()
                .title('dental_plan_procedure_steps_add_new')
                .placeholder('dental_plan_procedure_steps_label')
                .targetEvent(ev)

            this.dialog.show(confirm)
                .then(value => {
                    let step = {value};
                    this.steps.push(step);
                    this.currentStep = step;
                });
        }

        isItemActive(item) {
            return _.isEqual(item, this.currentStep);
        }

        handleDone() {
            if (this.currentStep.is_done) this.currentStep.done_at = this.moment().format(this.dateTimeFormat);
            else this.currentStep.done_at = null;
        }

        handleCommentChange() {
            if (_.isEmpty(this.currentStep.comment) && this.currentStep.is_done) {
                this.currentStep.is_done = false;
                this.handleDone();
            }

            if (!_.isEmpty(this.currentStep.comment) && !this.currentStep.is_done) {
                this.currentStep.is_done = true;
                this.handleDone();
            }
        }

        handleSteps() {
            return _.concat(
                this.handleFromSteps(), this.handleNotFromSteps()
            )
        }

        handleFromSteps() {
            return _.chain(this.procedure.steps).cloneDeep().map(item => {
                let stepComment = _.find(this.procedure['step_comments'], i => i['step_uid'] == item['step_uid']);
                if (stepComment) return _.chain(stepComment).assign(item, {fromStep: true}).value();
                else return _.assign(item, {fromStep: true});
            }).value();
        }

        handleNotFromSteps() {
            let stepIds = _.map(this.procedure.steps, 'step_uid');
            return _.chain(this.procedure['step_comments'])
                .filter(i => !_.includes(stepIds, i['step_uid']))
                .map(i => _.assign(i, {fromStep: false}))
                .value();
        }

        removeStep(step, ev) {
            let confirm = this.dialog.confirm()
                .multiple(true)
                .targetEvent(ev)
                .ariaLabel('remove step')
                .ok(this.$translate.instant('dental_module_ok'))
                .cancel(this.$translate.instant('dental_module_cancel'))
                .title(this.$translate.instant('dental_plan_procedure_steps_remove_confirm', step));

            this.dialog.show(confirm).then(() => {
                this.steps = _.without(this.steps, step);
                this.currentStep = this.getCurrentStep();
            });
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        focusOnOpen: false,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PlanProcedureStepsDialogCtrl,
        template: require("../views/plan-procedure-steps-dialog.tpl.html"),
    };


})();