/**
 * Created by BETALOS on 12/10/2016.
 */
(function () {
    'use strict';

    class ActorService {
        constructor($q, $http) {
            this.$q = $q;
            this.$http = $http;
        }

        static get $inject() {
            return ['$q', '$http'];
        }

        getSpecialities() {
            const deferred = this.$q.defer();
            const url = `/api/medical-speciality/`;

            this.$http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        handleSpeciality(speciality) {
            const deferred = this.$q.defer();
            const url = `/api/medical-speciality/${_.isNil(speciality.id) ? '' : speciality.id + '/'}`;

            this.$http.post(url, speciality)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        getStaff(staff) {
            const deferred = this.$q.defer();
            const url = `/api/staff/${staff}/`;

            this.$http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        getStaffList() {
            const url = "/api/staff-list/";
            const deferred = this.$q.defer();

            this.$http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        handleStaff(staff) { //user
            const deferred = this.$q.defer();
            const url = `/api/${staff['is_physician'] ? 'physician' : 'staff'}/${_.has(staff, 'id') ? staff.id + '/' : ''}?logout=1`;

            staff.users = _.reduce(staff.users, checkUser, [])

            this.$http.post(url, staff)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            function checkUser(result, user) {
                if (_.isNil(user.id)) user.password = user['new_password'];

                user.is_active = staff.is_active;

                result.push(user);
                return result;
            }

            return deferred.promise;
        }

        partialUpdateStaff(staff) {
            const deferred = this.$q.defer();
            const url = `/api/${staff['is_physician'] ? 'physician' : 'staff'}/${staff.id}/`;

            this.$http.put(url, staff)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

    }

    module.exports = ActorService;

})();