/**
 * Created by BETALOS on 19/11/2015.
 */
(function () {

    'use strict';

    class Acl {
        constructor(authService) {
            this.priority = 0;
            this.restrict = "A";
            this.service = authService;
        }

        static create() {
            return new Acl(...arguments);
        }

        link(scope, element, attrs) {
            let acl = scope.$eval(attrs['mnAcl']);
            let allowEmpty = element.is('[allow-empty]');
            let behavior = _.get(acl, 'behavior', 'remove');

            if (allowEmpty && _.isEmpty(acl)) return;
            else if (!this.service.aclHandler(acl)) {
                if (behavior == 'disable') element.attr('disabled', 'disabled');
                else if (behavior == 'read-only') element.attr('readonly', true);
                else element.remove();
            }
        }
    }

    Acl.create.$inject = ['authService'];

    module.exports = Acl.create;

})();
