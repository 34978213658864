/* edited 07/05/2018 */
(function () {
    'use strict';

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ExamExportModelCtrl,
        template: require("exam/views/export-exam-model-dialog.tpl.html"),
    };

    ExamExportModelCtrl.$inject = ["$mdDialog", "examService", "$state"];

    function ExamExportModelCtrl($mdDialog, examService, $state) {
        var vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.abort = $mdDialog.hide;

        function init() {
            vm.promise = examService.getExamTemplates()
                .then(success);

            function success(data) {
                vm.items = data;
                vm.query = {id: vm.model.id};
            }
        }

        function submit() {
            examService.saveExamModel(vm.model).then(success);

            function success() {
                if ($state.includes("app.visit.exam")) examService.editModelState(vm.model);
                else examService.formModelState(vm.model.template, null, false, true);

                $mdDialog.hide(true);
            }
        }
    }

})();