/**
 * Created by BETALOS on 26/02/2016.
 */
(function () {
    'use strict';

    const INVALID_CLASS = 'md-datepicker-invalid';

    class Date {
        constructor(moment, system) {
            this.priority = 1;
            this.restrict = "A"
            this.require = ['^ngModel', '^mdDatepicker'];

            this.moment = moment;
            this.dateFormat = system['date_format'].js;
        }

        static create() {
            return new Date(...arguments);
        }

        link(scope, element, attrs, ctrl) {
            this.patchDatePickerCtrl(ctrl);

            $('.md-datepicker-triangle-button', element).attr('tabindex', -1);

            ctrl[0].$parsers.push((input) => this.parser(input));
            ctrl[0].$formatters = _.castArray((model) => this.formatter(model, ctrl[1]));

            element.find(".md-datepicker-input").on("change", (ev) => this.handler(ev.currentTarget, ctrl[1], scope));
        }


        formatter(valueFromModel, ctrl) {
            let value = null

            if (_.isString(valueFromModel)) {
                value = this.moment(valueFromModel, this.dateFormat).toDate();
            }

            else if (this.moment.isMoment(valueFromModel)) {
                value = valueFromModel.toDate();
            }

            else if (_.isDate(valueFromModel)) value = valueFromModel;

            ctrl.onExternalChange(value);

            return value;
        }

        parser(valueFromInput) {
            if (valueFromInput) {
                if (_.isString(valueFromInput)) {
                    let format = valueFromInput.includes('-') ? "YYYY-MM-DD" : this.dateFormat;
                    return this.moment(valueFromInput, format).format(this.dateFormat);
                }

                if (_.isDate(valueFromInput)) {
                    return this.moment(valueFromInput).format(this.dateFormat);
                }

            } else return null;
        }

        handler(node, ctrl) {
            let _d;
            let $input = $(node);

            const inputVal = $input.val();

            switch ($input.val().length) {
                case 2:
                    _d = this.moment(inputVal, "YY");
                    break;
                case 4:
                    _d = this.moment(inputVal, "MMYY");
                    if (_d.isValid() && parseInt(_d.format("YYYY")) > parseInt(this.moment().format("YYYY"))) {
                        _d.subtract(100, "years");
                    }
                    else _d = this.moment(inputVal, "YYYY");

                    break;
                case 6:
                    _d = this.moment(inputVal, "DDMMYY");
                    if (parseInt(_d.format("YYYY")) > parseInt(this.moment().format("YYYY"))) {
                        _d.subtract(100, "years");
                    }
                    break;
                case 8:
                    _d = this.moment(inputVal, "DDMMYYYY");
                    break;
                default :
                    _d = this.moment(inputVal, this.dateFormat);
                    if (!_d.isValid()) _d = false;
            }

            const value = (_d === false || !_d.isValid()) ? null : _d.toDate();

            ctrl.setModelValue(value);
            ctrl.onExternalChange(value);
        }

        patchDatePickerCtrl(ctrl) {
            ctrl[1].updateErrorState = function (opt_date) {
                let date = opt_date || this.date;

                // Clear any existing errors to get rid of anything that's no longer relevant.
                this.clearErrorState();

                if (this.dateUtil.isValidDate(date)) {
                    // Force all dates to midnight in order to ignore the time portion.
                    date = this.dateUtil.createDateAtMidnight(date);

                    if (this.dateUtil.isValidDate(this.minDate)) {
                        let minDate = this.dateUtil.createDateAtMidnight(this.minDate);
                        this.ngModelCtrl.$setValidity('mindate', date >= minDate);
                    }

                    if (this.dateUtil.isValidDate(this.maxDate)) {
                        let maxDate = this.dateUtil.createDateAtMidnight(this.maxDate);
                        this.ngModelCtrl.$setValidity('maxdate', date <= maxDate);
                    }

                    if (angular.isFunction(this.dateFilter)) {
                        this.ngModelCtrl.$setValidity('filtered', this.dateFilter(date));
                    }
                } else {
                    // The date is seen as "not a valid date" if there is *something* set
                    // (i.e.., not null or undefined), but that something isn't a valid date.
                    this.ngModelCtrl.$setValidity('valid', date == null);
                }

                angular.element(this.inputContainer).toggleClass(INVALID_CLASS, !this.ngModelCtrl.$valid);
            }
        }
    }

    Date.create.$inject = ["moment", "system"];

    module.exports = Date.create;

})();