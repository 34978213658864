/**
 * Created by Hp on 04/05/2017.
 */
(function () {

    'use strict';

    var ContractTerminateDialog = {
        controller: ContractTerminateDialogCtrl,
        controllerAs: "vm",
        template: require("billing/views/contract-terminate-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true
    };

    ContractTerminateDialogCtrl.$inject = ["$mdDialog", "visitService", "$translate"];

    function ContractTerminateDialogCtrl($mdDialog, visitService, $translate) {
        var vm = this;
        vm.cancel = $mdDialog.cancel;
        vm.save = save;

        init();

        function init() {

        }

        function save() {
            $mdDialog.hide(vm.termination ? vm.terminate_detail : vm.stand_by_detail);
        }
    }

    module.exports = ContractTerminateDialog;

})();