/**
 * Created by Hp on 12/07/2017.
 */
(function () {

    'use strict';

    class ToastCtrl {
        constructor($mdToast, $timeout) {
            this.toast = $mdToast;
            this.timeout = $timeout;
            this.label = this.label ? this.label : "loading_medicines";
        }

        $onInit() {
            if (this.handle.finally) this.handle.finally(() => this.toast.hide());
            else if (_.isFunction(this.handle)) this.handle().then((response) => this.toast.hide(response), (err) => this.toast.hide(err));
            else this.timeout(() => this.toast.hide(), 200);
        }

        static get $inject() {
            return ["$mdToast", "$timeout"];
        }
    }


    const advancedToast = {
        hideDelay: 0,
        controllerAs: 'vm',
        controller: ToastCtrl,
        bindToController: true,
        position: 'bottom left',
        template: `
            <md-toast class="mn-progress-toast">
                <md-progress-circular md-diameter="25" md-mode="indeterminate" ></md-progress-circular>
                <div class="layout-column flex">
                    <span class="md-toast-text flex"  ng-bind="vm.label | translate"></span>
                    <span ng-if="vm.description" class="flex mn-toast-subtext" ng-bind="vm.description | translate"></span>
                </div>
            </md-toast>
        `,
    };

    class ToastConfig {
        constructor($mdToastProvider) {
            $mdToastProvider.addPreset('mnAdvancedToast', {
                methods: ['handle', 'label', 'description'],
                options: () => advancedToast
            });
        }

        static create() {
            return new ToastConfig(...arguments);
        }
    }

    ToastConfig.create.$inject = ['$mdToastProvider'];

    module.exports = ToastConfig.create;


})();