/**
 * Created by Hp on 03/05/2017.
 */


(function () {
    'use strict';
    module.exports = InvoiceFormCtrl;

    InvoiceFormCtrl.$inject = ["dragulaService", "$stateParams", "patientService", "$translate", "system", "$mdDialog", 'billingService', "saleService", "$state", "$mdToast", "$scope", "dentalService"];

    function InvoiceFormCtrl(dragulaService, $stateParams, patientService, $translate, system, $mdDialog, billingService, saleService, $state, $mdToast, $scope, dentalService) {
        var vm = this;
        var currentInvoiceId = $stateParams['invoiceId'];
        var currentVisits = $stateParams['visits'];
        var currentBeneficiary = $stateParams['beneficiary'];
        var currentBeneficiaryType = $stateParams['beneficiaryType'];
        var isContract = $stateParams['isContract'];
        var invoiceDate = $stateParams['invoiceDate'];


        vm.$onInit = init;
        var dateFormat = system['date_format'].js;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.onPaginate = onPaginate;
        vm.SelectVisit = SelectVisit;
        vm.showProcedures = showProcedures;
        vm.removeVisit = removeVisit;
        vm.removeProcedure = removeProcedure;

        vm.getTotalPrice = getTotalPrice;
        vm.getTotalDiscount = getTotalDiscount;
        vm.invoiceTotal = invoiceTotal;

        vm.selectDelivery = selectDelivery;
        vm.selectArticle = selectArticle;
        vm.selectStockArticle = selectStockArticle;
        vm.addEmptyLine = addEmptyLine;
        vm.submit = submit;
        vm.validateInvoice = validateInvoice;
        vm.invalidateInvoice = invalidateInvoice;
        vm.removeInvoice = removeInvoice;
        vm.cancel = goBack;
        vm.SelectProcedure = SelectProcedure;
        vm.getSubTotal = getSubTotal;
        vm.SelectVisitProcedure = SelectVisitProcedure;
        vm.SelectTreatmentPlan = SelectTreatmentPlan;
        //vm.SelectVisitProcedure = SelectVisitProcedure;
        vm.SelectDentalConsultation = SelectDentalConsultation;
        vm.prePrint = prePrint;
        $scope.$watch('vm.invoice.is_valid', readOnlyInvoice);
        var bag = "line-bag";

        function init() {
            vm.selectedVisit = null;
            vm.invoice = {};
            vm.invoice_details = [];
            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
            vm.is_new = !_.isNil(currentInvoiceId) ? false : true;
            vm.mnModel = 'Invoice';
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            vm.options = [5, 10, 20];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }
            if (!_.isNil(currentInvoiceId)) {
                vm.promise = billingService.getInvoice(currentInvoiceId).then(success);
            }
            else {
                vm.promise = null;
                vm.invoice = {
                    date: isContract && invoiceDate ? invoiceDate : moment().format(dateFormat),
                    deadline_date: isContract && invoiceDate ? invoiceDate : moment().format(dateFormat),
                    invoice_details: [],
                    beneficiary_type: currentBeneficiaryType ? currentBeneficiaryType : 'PATIENT',
                    is_contract_invoice: isContract ? isContract : false
                };
                if (currentBeneficiary && currentBeneficiaryType == 'PATIENT') patientCallback(currentBeneficiary)
                else vm.invoice.beneficiary = currentBeneficiary;
                getData();
                loadVisits(currentVisits);
            }
            initDrag();

            function success(data) {
                vm.invoice = data;
            }
        }

        function readOnlyInvoice() {
            vm.readOnly = (vm.invoice && vm.invoice.is_valid) ? vm.invoice.is_valid : false;
        }

        function removePatient() {
            vm.invoice.beneficiary = null;
        }

        function initDrag() {
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            var drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(moveLine, 400));
        }

        function moveLine() {
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id, false)
                .then(doneCallback);

            function doneCallback(data) {
                vm.invoice.beneficiary = data;
            }
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            if (vm.invoice.invoice_details.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function SelectVisit(ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/visit-search-dialog'), {
                targetEvent: ev,
                locals: {
                    beneficiary: vm.invoice.beneficiary,
                    beneficiary_type: vm.invoice['beneficiary_type']
                }
            })).then(function doneCallBack(data) {
                loadVisits(data)

            });
            getData();

        }

        function loadVisits(data) {
            var InvoicedVisits = [];
            var noProceduresVisits = [];
            vm.Processed = 0;
            if (isContract) {
                _.forEach(data, function (contract) {
                    refreshLinesByContractLines(contract, _.reject(contract.current_instance.details, function (line) {
                        if (_.get(contract, 'start_at') != _.get(contract, 'current_instance.start_at')) {
                            return _.get(line, 'is_inclusive', false)
                        }
                        else {
                            return false;
                        }
                    }));
                });
            } else {
                _.forEach(data, function (visit) {
                    loadVisitProcedure(visit, InvoicedVisits, data.length, noProceduresVisits);
                });
            }

        }

        function refreshLinesByContractLines(contract, details) {
            let invoice_detail = {
                description: `${ contract.type == 'SUBSCRIPTION' ? $translate.instant('add_subscription') : $translate.instant('add_renting') } ${$translate.instant('number')}${contract.number}`,
                sub_details: _.map(details, (d) => {
                    return _.assign(_.pick(d, ['code', 'description', 'qte', 'price', 'sub_total']), {
                        discount: 0,
                        contract_instance: {id: contract.current_instance.id},
                        details_date: contract.current_instance.next_bill_period || contract.current_instance.start_at
                    })
                })
            }
            if (!_.isEmpty(invoice_detail)) vm.invoice.invoice_details.push(invoice_detail)
        }

        function refreshLinesByVisit(visit, visit_details, totallyInvoicedVisits) {
            var invoice_detail = {
                description: visit['patient_fullname'] + " , " + $translate.instant('visit_at') + " " + visit.visit_date,
                sub_details: visit_details
            };
            var visit_detail = _.chain(vm.invoice.invoice_details).find(function (item) {
                return _.chain(item.sub_details).filter(function (sub_detail) {
                    return !_.isNil(sub_detail.visit) && sub_detail.visit.id == visit.id
                }).value().length > 0 || item.description == invoice_detail.description
            }).value()

            if (!_.isNil(visit_detail)) {
                var sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(visit_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });

                visit_detail.sub_details = visit_detail.sub_details.concat(sub_detail_append);
            }
            else {
                if (!_.isEmpty(visit_details)) vm.invoice.invoice_details.push(invoice_detail);
                else totallyInvoicedVisits.push(visit.visit_date);
            }
        }

        function loadVisitProcedure(visit, InvoicedVisits, dataLength, noProceduresVisits) {
            if (_.isEmpty(visit.procedures)) noProceduresVisits.push(visit.visit_date)
            else billingService.getVisitNonInvoicedProcedure(visit.id, vm.invoice.id).then(success);

            function success(data) {
                var visit_details = [];
                _.forEach(data, function (procedure) {
                    var visit_detail = {
                        code: !_.isNil(procedure.procedure) ? procedure.procedure.code : procedure.name,
                        description: procedure.name,
                        qte: procedure.qte,
                        price: procedure.price,
                        discount: _.chain(visit).get('financial_status.procedures_prices').find({uid: procedure.uid}).get('global_discount', 0).value(),
                        procedure_uid: procedure.uid,
                        visit: {id: visit.id}
                    };
                    visit_details.push(visit_detail);
                });
                refreshLinesByVisit(visit, visit_details, InvoicedVisits);
                vm.Processed += 1;
                if (vm.Processed == dataLength && InvoicedVisits.length > 0) {
                    showNotification($translate.instant('visits_totally_invoiced') + ': <br>' + _.join(InvoicedVisits, '<br>'));
                }
            }
        }

        function showNotification(notification_content) {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .htmlContent(notification_content)
                    .ariaLabel('validation_warning')
                    .ok($translate.instant('confirm'))
            );
        }

        function showProcedures(idx) {
            if (vm.selectedVisit == idx) {
                vm.selectedVisit = -1;
            } else {
                vm.selectedVisit = idx;
            }
        }

        function removeVisit(index) {
            vm.invoice.invoice_details.splice((vm.query.page - 1) * vm.query.limit + index, 1);

        }

        function removeProcedure(line, item) {
            _.remove(_.find(vm.invoice.invoice_details, line).sub_details, item);

        }

        function refreshLinesByArticle(article_list, details) {
            _.forEach(article_list, function (article) {
                var detail_line = {
                    code: article.code,
                    description: article.short_desc,
                    qte: 1,
                    price: article.sale_price,
                    discount: 0.0,
                    article: _.cloneDeep(article)
                };
                var line_not_exist = _.isEmpty(_.filter(details, function (sub_detail) {
                    return sub_detail.code == detail_line.code;
                }));
                if (line_not_exist) details.push(detail_line);
            });
        }

        function selectStockArticle(line, ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(function selectLineCallBack(data) {
                refreshLinesByArticle(data, line.sub_details);
            });
        }

        function refreshByDeliveryLines(invoice_detail) {

            var delivery_detail = _.find(vm.invoice.invoice_details, {description: invoice_detail.description});
            if (!_.isNil(delivery_detail)) {
                var sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(delivery_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });
                delivery_detail.sub_details = delivery_detail.sub_details.concat(sub_detail_append);
            }

            else if (!_.isEmpty(invoice_detail.sub_details)) vm.invoice.invoice_details.push(invoice_detail);
        }

        function selectDeliveryCallBack(data) {
            _.forEach(data, function (delivery) {
                saleService.getDeliveryNonInvoicesLines(delivery.number).then(success);

                function success(lines) {
                    var invoice_detail = {
                        description: $translate.instant('delivery') + ' ' + delivery.number,
                        sub_details: []
                    };
                    _.forEach(lines, function (line) {
                        var detail_line = {
                            code: line.code,
                            description: line.short_desc,
                            qte: line.qte,
                            price: line.unit_price,
                            discount: 0.0,
                            article: _.cloneDeep(line.article),
                            delivery: _.cloneDeep(delivery)
                        };
                        var line_not_exist = _.isEmpty(_.filter(invoice_detail.sub_details, function (sub_detail) {
                            return sub_detail.code == detail_line.code;
                        }));
                        if (line_not_exist) invoice_detail.sub_details.push(detail_line);
                    });
                    refreshByDeliveryLines(invoice_detail);
                }
            });
        }

        function selectDelivery(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-receipt",
                    modalTitle: "delivery_list",
                    modalModel: "stock.DeliveryForm",
                    search: _.assign({
                        is_deleted: {$ne: true},
                        is_valid: {$ne: false}
                    }, vm.invoice['beneficiary_type'] == 'PATIENT' ? {patient: vm.invoice.beneficiary.id} : {organization: vm.invoice.beneficiary.id}),
                }
            })).then(selectDeliveryCallBack);
        }

        function selectArticleCallBack(data) {
            var invoice_detail = {description: $translate.instant('other_supply'), sub_details: []};

            refreshLinesByArticle(data, invoice_detail.sub_details);

            var other_detail = _.find(vm.invoice.invoice_details, {description: invoice_detail.description});

            if (!_.isNil(other_detail)) {
                var sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(other_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });
                other_detail.sub_details = other_detail.sub_details.concat(sub_detail_append);
            }
            else if (!_.isEmpty(invoice_detail.sub_details)) vm.invoice.invoice_details.push(invoice_detail);
        }

        function selectArticle(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(selectArticleCallBack);
        }

        function addEmptyLine() {
            vm.invoice.invoice_details.push({is_comment: true});
        }

        function getTotalPrice(invoice_detail) {
            return _.sumBy(invoice_detail.sub_details, function (sub_detail) {
                return sub_detail.sub_total || ((sub_detail.qte * sub_detail.price) - sub_detail.discount);
            });
        }

        function getTotalDiscount(invoice_detail) {
            return _.sumBy(invoice_detail.sub_details, 'discount');
        }

        function invoiceTotal() {
            return _.sumBy(vm.invoice.invoice_details, function (invoice_detail) {
                return getTotalPrice(invoice_detail);
            });
        }

        function getSubTotal(item) {
            return item.sub_total || (((item.qte | 0.0) * (item.price || 0.0)) - item.discount);
        }

        function assignBeneficiaryModel() {
            if (vm.invoice['beneficiary_type'] === 'PATIENT') vm.invoice.beneficiary = _.assign(vm.invoice.beneficiary, {
                _module: "patient.models",
                _model: "Patient"
            });
            if (vm.invoice['beneficiary_type'] === 'ORGANIZATION') vm.invoice.beneficiary = _.assign(vm.invoice.beneficiary, {
                _module: "shared.insurance.models",
                _model: "Organization"
            });
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                assignBeneficiaryModel();
                vm.promise = billingService.saveInvoice(vm.invoice).then(success);
            }

            function success(data) {
                vm.prevent_save = false;

                if (with_cancel) {
                    goBack();
                }
                else {
                    saveSuccess(data);
                }
            }
        }

        function saveSuccess(data) {
            vm.invoice = data;
            if (_.isNil(currentInvoiceId)) $state.go('app.billing.invoice-form', {invoiceId: data.id}, {location: 'replace'});
            vm.prevent_invalidate = false;
            vm.prevent_validate = false;
        }

        function validateInvoice() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('validation_warning'))
                        .ariaLabel('validation_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_validate = true;
                    let contracts_ids = _.chain(vm.invoice.invoice_details).map('sub_details').flatten().filter((l) => {
                        return !_.isNil(l.contract_instance)
                    }).map('contract_instance.id').uniq().value();
                    if (!_.isEmpty(contracts_ids)) {
                        vm.promise = billingService.handleNextBillPeriod(contracts_ids).then((res) => {
                            vm.promise = billingService.updateBillingDocument(vm.invoice.id, 'invoice', {is_valid: true}).then((data) => {
                                vm.invoice = data
                            });

                        }, _.noop)
                    }
                    else {
                        vm.promise = billingService.updateBillingDocument(vm.invoice.id, 'invoice', {is_valid: true}).then((data) => {
                            vm.invoice = data
                        });
                    }

                    // if (isContract && !_.has(vm.invoice, 'id') && !_.isEmpty(vm.invoice.invoice_details)) {
                    //     billingService.handleNextBillPeriod(currentVisits[0].id).then(_.noop())
                    // }

                })
            }
        }

        function invalidateInvoice() {
            if (!vm.prevent_invalidate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('invalidate_warning'))
                        .ariaLabel('invalidate_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_invalidate = true;
                    let contracts_lines = _.chain(vm.invoice.invoice_details).map('sub_details').flatten().filter((l) => {
                        return !_.isNil(l.contract_instance)
                    }).value();

                    if (!_.isEmpty(contracts_lines)) {
                        if (_.every(contracts_lines, (line) => {
                            return line.details_date == line.contract_instance.prev_bill_date
                        })) {
                            vm.promise = billingService.handleNextBillPeriod(_.chain(contracts_lines).map('contract_instance.id').uniq().value(), true).then((data) => {
                                vm.promise = billingService.invalidateInvoice(vm.invoice.id).then(doneCallBack);

                            })
                        }
                        else {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .parent($(document.body))
                                    .clickOutsideToClose(true)
                                    .title('')
                                    .textContent($translate.instant('contract_invoice_impossible_invalidate'))
                                    .ariaLabel('contract_invoice_impossible_invalidate')
                                    .ok('Ok')
                            );
                        }
                    }
                    else {
                        vm.promise = billingService.invalidateInvoice(vm.invoice.id).then(doneCallBack);
                    }
                });
            }

            function doneCallBack(data) {
                vm.prevent_invalidate = false;

                $state.go('app.billing.invoice-form', {invoiceId: data.id}, {location: 'replace'});
                vm.invoice = data;
            }
        }

        function removeInvoice(invoice) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {

                    vm.prevent_remove = true;
                    vm.promise = billingService.deleteInvoice(invoice.id).then(success);
                }
            });

            function success(data) {
                goBack();
            }
        }

        function goBack() {
            // window.history.back();
            $state.go('app.billing.invoice');
        }

        function SelectProcedure(data) {
            var invoice_detail = {description: $translate.instant('other_supply'), sub_details: []};

            _.forEach(data, function (procedure) {
                if (_.isNil(_.find(invoice_detail.sub_details, {code: procedure.code}))) {
                    invoice_detail.sub_details.push({
                        code: procedure.code || procedure.name,
                        description: procedure.name,
                        qte: 1,
                        price: procedure.price,
                        procedure_uid: procedure.uid,
                        discount: 0.0
                    });
                }
            });

            var other_detail = _.find(vm.invoice.invoice_details, {description: invoice_detail.description});
            if (!_.isNil(other_detail)) {
                var sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(other_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });
                other_detail.sub_details = other_detail.sub_details.concat(sub_detail_append);
            }
            else if (!_.isEmpty(invoice_detail.sub_details)) vm.invoice.invoice_details.push(invoice_detail);
        }

        function SelectVisitProcedure(line, ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/procedure-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(function selectLineCallBack(data) {
                refreshLines(data, line.sub_details);
            });

            function refreshLines(procedures, sub_details) {

                _.forEach(procedures, function (procedure) {
                    if (_.isNil(_.find(sub_details, {code: procedure.code}))) {
                        sub_details.push({
                            code: procedure.code || procedure.name,
                            description: procedure.name,
                            qte: 1,
                            price: procedure.price,
                            procedure_uid: procedure.uid,
                            discount: 0.0
                        });
                    }
                });
            }
        }

        function prePrint() {
            assignBeneficiaryModel();
            return billingService.saveInvoice(vm.invoice);
        }

        function SelectTreatmentPlan(ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/dental-treatment-plan-search-dailog'), {
                targetEvent: ev,
                locals: {
                    beneficiary: vm.invoice.beneficiary,
                    beneficiary_type: vm.invoice['beneficiary_type']
                }
            })).then(function doneCallBack(data) {
                var InvoicedPlans = [];
                var noProceduresPlans = [];
                vm.processedPlans = 0;
                if (!_.isEmpty(data)) {
                    _.forEach(data, function (plan) {
                        loadPlanProcedure(plan, InvoicedPlans, data.length);
                    })
                }
            });
        }

        function loadPlanProcedure(plan, InvoicedPlans, dataLength) {
            billingService.getPlanNonInvoicedProcedure(plan.id, vm.invoice.id).then((data) => {
                handlePlanProcedures(plan, data, InvoicedPlans)
                vm.processedPlans += 1;
                if (InvoicedPlans.length > 0 && vm.processedPlans == dataLength) {
                    showNotification($translate.instant('treatment_plan_totally_invoiced') + '<br>' + _.join(InvoicedPlans, '<br>'));
                }
            });

            function handlePlanProcedures(plan, procedures, InvoicedPlans) {
                let plan_details = [];
                _.forEach(procedures, function (procedure) {
                    let desc = `${procedure.name} ${!_.isEmpty(procedure.teeth) ? '-' : ''} ${_.join(procedure.teeth, ',')}`;
                    let detail_idx = _.findIndex(plan_details, {code: procedure.code, description: desc});
                    if (detail_idx > -1) _.update(plan_details, `[${detail_idx}].qte`, (e) => {
                        return e + 1;
                    })
                    else {
                        plan_details.push({
                            code: procedure.code,
                            description: desc,
                            qte: procedure.qte || 1,
                            price: procedure.price,
                            discount: 0.0,
                            procedure_uid: procedure.line_uid,
                            treatment_plan: {id: plan.id}
                        });
                    }
                });

                if (!_.isEmpty(plan_details)) refreshLinesByTreatmentPlan(plan, plan_details);
                else InvoicedPlans.push(`${plan['patient_fullname']} - ${plan.title}`);
            }

            function refreshLinesByTreatmentPlan(plan, plan_details) {
                let invoice_detail = {
                    description: `${plan['patient_fullname']} - ${plan.title}`,
                    sub_details: plan_details
                };
                let plan_detail = _.find(vm.invoice.invoice_details, function (item) {
                    return !_.chain(item.sub_details).find(function (sub_detail) {
                        return !_.isNil(sub_detail.treatment_plan) && sub_detail.treatment_plan.id == plan.id
                    }).isNil().value() || item.description == invoice_detail.description
                });
                if (!_.isNil(plan_detail)) {
                    _.forEach(invoice_detail.sub_details, function (detail) {
                        let detail_idx = _.findIndex(plan_detail.sub_details, {
                            code: detail.code,
                            description: detail.description
                        });
                        if (detail_idx == -1) {
                            plan_detail.sub_details.push(detail);
                        }
                    });
                }
                else {
                    vm.invoice.invoice_details.push(invoice_detail);
                }
            }
        }

        function SelectDentalConsultation(ev) {
            $mdDialog.show(_.assign(require('../dialogs/dental-visit-search-dialog'), {
                targetEvent: ev,
                locals: {
                    beneficiary: vm.invoice.beneficiary,
                    beneficiary_type: vm.invoice['beneficiary_type']
                }
            })).then(function doneCallBack(data) {
                var InvoicedVisits = [];
                vm.processedDentals = 0;
                if (!_.isEmpty(data)) {
                    _.forEach(data, function (visit) {
                        loadDentalVisitProcedure(visit, InvoicedVisits, data.length);
                    })
                }

            });

            function loadDentalVisitProcedure(visit, InvoicedVisits, dataLength) {
                billingService.getDentalNonInvoicedProcedure(visit.id, vm.invoice.id).then((data) => {
                    handleDentalProcedures(visit, data, InvoicedVisits)
                    vm.processedDentals += 1;
                    if (visit.length > 0 && vm.processedDentals == dataLength) {
                        showNotification($translate.instant('dental_visit_totally_invoiced') + '<br>' + _.join(InvoicedVisits, '<br>'));
                    }
                });

                function handleDentalProcedures(visit, procedures, InvoicedVisits) {
                    let visit_details = [];
                    _.forEach(procedures, function (procedure) {
                        let desc = `${procedure.name} ${!_.isEmpty(procedure.teeth) ? '-' : ''} ${ $translate.instant('dental_procedure_applied_from', procedure)}`;
                        let detail_idx = _.findIndex(visit_details, {code: procedure.code, description: desc});
                        if (detail_idx > -1) _.update(visit_details, `[${detail_idx}].qte`, (e) => {
                            return e + 1;
                        })
                        else {
                            let price = _.chain(visit.procedures_prices).find(function (e) {
                                return _.get(procedure, 'dental_procedure_id') == _.get(e, 'dental_procedure_id')
                            }).value()
                            visit_details.push({
                                code: procedure.code,
                                description: desc,
                                qte: procedure.qte || 1,
                                price: _.get(price, 'price', 0),
                                discount: _.chain(visit).get('procedures_prices').find({uid: procedure.procedure_uid}).get('global_discount', 0).value(),
                                procedure_uid: procedure.procedure_uid,
                                dental_consultation: {id: visit.id}
                            });
                        }
                    });


                    if (!_.isEmpty(visit_details)) refreshLinesByDentalVisit(visit, visit_details);
                    else InvoicedVisits.push(`${_.get(visit, 'patient_fullname')} - ${_.get(visit, 'visit_date')}`);
                }

                function refreshLinesByDentalVisit(visit, visit_details) {
                    let invoice_detail = {
                        description: `${_.get(visit, 'patient_fullname')} -${$translate.instant('visit_at')} ${_.get(visit, 'visit_date')}`,
                        sub_details: visit_details
                    };
                    let dental_detail = _.find(vm.invoice.invoice_details, function (item) {
                        return !_.chain(item.sub_details).find(function (sub_detail) {
                            return !_.isNil(sub_detail.dental_consultation) && sub_detail.dental_consultation.id == visit.id
                        }).isNil().value() || item.description == invoice_detail.description
                    });
                    if (!_.isNil(dental_detail)) {
                        _.forEach(invoice_detail.sub_details, function (detail) {
                            let detail_idx = _.findIndex(dental_detail.sub_details, {
                                code: detail.code,
                                description: detail.description
                            });
                            if (detail_idx == -1) {
                                dental_detail.sub_details.push(detail);
                            }
                        });
                    }
                    else {
                        vm.invoice.invoice_details.push(invoice_detail);
                    }
                }
            }
        }
    }
})();