/**
 * Edited by Amine 22/10/2019.
 */

(function () {

    'use strict';

    class DentalConsultationSearchDialogCtrl {
        constructor($mdDialog, paymentService, $translate, system) {
            this.dialog = $mdDialog;
            this.paymentService = paymentService;
            this.dateFormat = system['date_format'].js;

            this.selected = [];
            this.query = {
                limit: 10,
                page: 1,
                order: "-visit_date",
                hide_paid: false,
                hide_no_amount: true,
                patientFilter: {}
            };

            this.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            };

            this.options = [20, 15, 10, 5];
            this.selectedConsult = null;
            this.consults = [];
            this.patient = this.patient || null;
        }

        static get $inject() {
            return ["$mdDialog", "paymentService", "$translate", "system"];
        }

        $onInit() {
            this.promise = this.paymentService
                .getDentalConsultation(_.assign(this.query, {patient: _.get(this.patient, "id"), only_patient: true}))
                .then(data => {
                    this.selectedConsult = null;
                    this.consultTotal = data.length;
                    this.consults = data.list;
                    if (this.consultTotal < ((this.query.page - 1) * this.query.limit)) {
                        this.query.page = 1;
                    }
                });
        }

        getConsults() {
            const order = this.query.order[0] === '-' ? 'desc' : 'asc';
            const min = (this.query.page - 1) * this.query.limit, max = (this.query.page) * this.query.limit;

            return _.chain(this.consults)
                .orderBy(consult => moment(consult.visit_date, this.dateFormat).valueOf(), order)
                .slice(min, max)
                .value();
        }

        onConsultPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
        }

        addSelection() {
            this.dialog.hide(this.selected);
        }

        showProcedures(consult, $event) {
            $event.stopPropagation();

            if (this.selectedConsult === consult.id) this.selectedConsult = -1;
            else this.selectedConsult = consult.id;
        }

        onConsultReorder(order) {
            this.query = _.assign(this.query, {order: order});
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: DentalConsultationSearchDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/dental-consultation-search-modal.tpl.html"),
    };


})();