/**
 * Created by amine on 24/09/2018.
 */

(function () {
    'use strict';

    class TeethProceduresCtrl {
        constructor() {
        }

        static get $inject() {
            return [];
        }

        $onInit() {
        }
    }

    module.exports = {
        controller: TeethProceduresCtrl,
        controllerAs: "vm",
        bindings: {
            plan: "<plan"
        },
        template: require('dental-consultation/views/teeth-procedures.tpl.html'),
    };
})();
